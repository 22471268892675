import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { useQueryParam, StringParam, BooleanParam, ArrayParam, withDefault, NumberParam } from 'use-query-params';
import 'firebase/compat/auth';

function CurryChart(props: {
  title: string;
  component: React.FunctionComponent<{
    shopIds: string[],
    shopType: string;
    salesChannels?: Array<string> | null;
    salesMethods?: Array<string> | null;
    period: 'day' | 'week' | 'month' | 'quarter' | 'year';
    stack: string;
    options: { [key: string]: any };
    title: string;
    ravedCustomers: boolean;
    limitLastNDaysActiveShops: number|null;
    excludeZeroYen: boolean;
    includeUnpaid: boolean;
  }>;
  options: { [key: string]: any };
  withShops: boolean;
}) {
  const [shops, setShops] = useState<Array<{ id: string; title: string; subTitle: string, shopIds: Array<string> }>>();
  const [shopIds] = useQueryParam('shopIds', withDefault(ArrayParam, [] as string[]));
  const [salesChannels = null] = useQueryParam('salesChannels', ArrayParam);
  const [salesMethods = null] = useQueryParam('salesMethods', ArrayParam);
  const [period = 'day'] = useQueryParam('period', StringParam);
  const [stack = 'none'] = useQueryParam('stack', StringParam);
  const [shopType = 'parentShops'] = useQueryParam('shopType', StringParam);
  const [ravedCustomers = false] = useQueryParam('ravedCustomers', BooleanParam);
  const [limitLastNDaysActiveShops = null] = useQueryParam('limitLastNDaysActiveShops', NumberParam);
  const [excludeZeroYen = false] = useQueryParam('excludeZeroYen', BooleanParam);
  const [includeUnpaid = false] = useQueryParam('includeUnpaid', BooleanParam);

    useEffect(() => {
    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = firebase
      .firestore()
      .collection('shops');

    if (shopType === 'parentShops') {
      query = query.where('kitchen_shop_id', '==', null);
    }

    const unregisterNotificationsObserver = query.orderBy('order').onSnapshot(async (snap) => {
      const records: Array<{ id: string; title: string; subTitle: string; shopIds: Array<string> }> = [];

      for (const doc of snap.docs) {
        if (shopIds.length > 0 && !shopIds.includes(doc.id)) {
          continue;
        }

        const ids: Array<string> = [doc.id];
        const childShopNames:string[] = [];
        if (shopType === 'parentShops') {
          const children = await firebase
            .firestore()
            .collection('shops')
            .where('kitchen_shop_id', '==', doc.id)
            .orderBy('order')
            .get();
          for (const child of children.docs) {
            ids.push(child.id);
            childShopNames.push(child.data().short_name);
          }
        }
        records.push({ id: doc.id, title: doc.data().short_name, subTitle: childShopNames.join(', ') , shopIds: ids });
      }

      setShops(records);
    });

    return () => {
      unregisterNotificationsObserver();
    };
  }, [shopType, shopIds]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">{props.title}</h1>
      </div>

      <h3>合計</h3>

      {shops ? (
        <>
          <props.component
            shopIds={shopIds as string[]}
            salesChannels={(salesChannels && salesChannels.length > 0) ? salesChannels as string[] : null}
            salesMethods={(salesMethods && salesMethods.length > 0) ? salesMethods as string[] : null}
            shopType={shopType!}
            options={props.options}
            period={period! as 'day' | 'week' | 'month' | 'quarter' | 'year'}
            stack={stack!}
            title={`${props.title}-合計`}
            ravedCustomers={!!ravedCustomers}
            limitLastNDaysActiveShops={limitLastNDaysActiveShops}
            excludeZeroYen={!!excludeZeroYen}
            includeUnpaid={!!includeUnpaid}
          />

          {props.withShops
            ? shops.map((shop) => (
                <div key={shop.id}>
                  <h3>{shop.title}</h3>
                  <small>{shop.subTitle}</small>
                  <props.component
                    shopIds={[shop.id]}
                    salesChannels={(salesChannels && salesChannels.length > 0) ? salesChannels as string[] : null}
                    salesMethods={(salesMethods && salesMethods.length > 0) ? salesMethods as string[] : null}
                    shopType={shopType!}
                    period={(period || 'day') as 'day' | 'week' | 'month' | 'quarter' | 'year'}
                    stack={stack || 'none'}
                    options={props.options}
                    title={`${props.title}-${shop.title}`}
                    ravedCustomers={!!ravedCustomers}
                    limitLastNDaysActiveShops={limitLastNDaysActiveShops}
                    excludeZeroYen={!!excludeZeroYen}
                    includeUnpaid={!!includeUnpaid}
                  />
                </div>
              ))
            : null}
        </>
      ) : (
        'loading...'
      )}
    </>
  );
}

export default CurryChart;
