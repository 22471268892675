import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { QueryRenderer } from '@cubejs-client/react';
import { Filter } from '@cubejs-client/core';
import dayjs from '../dayjs';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';
import CustomTooltip from '../CustomTooltip';

const md5 = require('md5');

const cubejsApi = Utils.cubejsApi();

function RavedCustomers(props: {
  shopIds: string[],
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'year';
  stack: string;
  options: { [key: string]: any };
  title: string;
}) {
  const formatXAxis = (tickItem) => {
    if (!tickItem) {
      return 'N/A';
    }
    const a = tickItem.split(',');
    return Utils.formatXAxisLabel(a[0], props.period);
  };

  const filters: Array<Filter> = [

  ];

  if (props.shopIds.length > 0) {
    if (props.shopType === 'parentShops') {
      filters.push({
        member: 'RavedCustomers.kitchenShopId',
        operator: 'equals',
        values: props.shopIds,
      });
    } else {
      filters.push({
        member: 'RavedCustomers.shopId',
        operator: 'equals',
        values: props.shopIds,
      });
    }
  }

  if (props.salesChannels) {
    filters.push({
      member: 'RavedCustomers.salesChannel',
      operator: 'equals',
      values: props.salesChannels,
    });
  }

  if (props.salesMethods) {
    const salesMethods: Array<string> = [];
    if (props.salesMethods.includes('app')) {
      salesMethods.push('appOrder');
    }
    if (props.salesMethods.includes('self_checkout')) {
      salesMethods.push('selfCheckoutOrder');
    }
    if (props.salesMethods.includes('qr_bento')) {
      salesMethods.push('qrBento');
    }
    if (salesMethods.length> 0){
      filters.push({
        member: 'Orders.salesMethod',
        operator: 'equals',
        values: salesMethods,
      });
    }
  }

  const segments: Array<string> = [];

  const colorsMap = {
    新規: '#253fe5',
    '既存(先月利用者)': '#78c457',
    '復活(先月未利用)': '#ff944d',
  };

  const colors = (word) => colorsMap[word];

  const sortOrder = ['既存(先月利用者)', '復活(先月未利用)', '新規'];
  const sortSeries = (a, b) => {
    let positionA = sortOrder.findIndex((label) => label === a.yValues[0]);
    let positionB = sortOrder.findIndex((label) => label === b.yValues[0]);

    if (positionA < 0) {
      positionA = 99999;
    }
    if (positionB < 0) {
      positionB = 99999;
    }

    if (positionA >= positionB) {
      return 1;
    }
    return -1;
  };

  const renderCharts = (resultSet) =>
    resultSet
      .seriesNames()
      .sort(sortSeries)
      .map((series, i) => (
        <Bar
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.yValues[0]}
          fill={colors(series.yValues[0])}
        />
      ));
  return (
    <>
      <QueryRenderer
        query={{
          measures: ['RavedCustomers.numberOfCustomers'],
          timeDimensions: [
            {
              dimension: 'RavedCustomers.cookedAt',
              granularity: props.period,
              dateRange: props.period === 'day' ? 'from 1 year ago to now' : '2019-06-01 to now',
            },
          ],
          order: {
            'RavedCustomers.cookedAt': 'asc',
          },
          segments,
          dimensions: ['RavedCustomers.newOrExistRavedCustomer'],
          filters,
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          const data = resultSet.chartPivot({ ...resultSet.normalizePivotConfig(), fillMissingDates: false });
          return (
            <>
              <ResponsiveContainer width="100%" height={600}>
                <BarChart
                  data={data}
                  stackOffset={(props.stack as 'expand') ?? 'none'}
                >
                  <Legend layout="horizontal" verticalAlign="top" align="center" />
                  <XAxis dataKey="x" tickFormatter={formatXAxis} />
                  <YAxis />
                  <CartesianGrid />
                  {renderCharts(resultSet)}
                  <Tooltip content={<CustomTooltip/>}/>`
                </BarChart>
              </ResponsiveContainer>
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default RavedCustomers;
