import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { QueryRenderer } from '@cubejs-client/react';
import dayjs from '../dayjs';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';
import CustomTooltip from '../CustomTooltip';

const cubejsApi = Utils.cubejsApi();
const md5 = require('md5');

function Passes(props: {
  shopId: string|null;
  shopType: string;
  salesChannels?: Array<string> | null;
  salesMethods?: Array<string> | null;
  period: 'day' | 'week' | 'month' | 'quarter' | 'year';
  stack: string;
  options: { [key: string]: any };
  title: string;
}) {
  const formatXAxis = (tickItem) => {
    if (!tickItem) {
      return 'N/A';
    }
    const a = tickItem.split(',');
    return Utils.formatXAxisLabel(a[0], props.period);
  };

  const segments: Array<string> = [];

  const sortOrder = ['サブスクリプション', '買い切り'];
  const sortSeries = (a, b) => {
    let positionA = sortOrder.findIndex((label) => label === a.yValues[0]);
    let positionB = sortOrder.findIndex((label) => label === b.yValues[0]);

    if (positionA < 0) {
      positionA = 99999;
    }
    if (positionB < 0) {
      positionB = 99999;
    }

    if (positionA >= positionB) {
      return 1;
    }
    return -1;
  };

  const colors = (word) => makeColor(word);

  const makeColor = (word: string) => {
    const seed = parseInt(md5(word), 16);
    const random = Math.floor(Math.abs(Math.cos(seed) * 16777215) % 16777215);
    let color = random.toString(16);
    while (color.length < 6) {
      color = `0${color}`;
    }
    return `#${color}`;
  };

  const renderCharts = (resultSet) =>
    resultSet
      .seriesNames()
      .sort(sortSeries)
      .map((series, i) => (
        <Bar
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.yValues[0]}
          fill={colors(series.yValues[0])}
        />
      ));

  return (
    <>
      <QueryRenderer
        query={{
          timeDimensions: [
            {
              dimension: 'Passes.orderedAt',
              granularity: props.period,
              dateRange: props.period === 'day' ? 'from 1 year ago to now' : '2019-06-01 to now',
            },
          ],
          measures: ['Passes.numberOfOrdered'],
          order: {
            'Passes.orderedAt': 'asc',
          },
          filters: [],
          segments,
          dimensions: ['Passes.passType'],
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          const data = resultSet.chartPivot({ ...resultSet.normalizePivotConfig(), fillMissingDates: false });
          return (
            <>
              <ResponsiveContainer width="100%" height={600}>
                <BarChart
                  data={data}
                  stackOffset={(props.stack as 'expand') ?? 'none'}
                >
                  <Legend layout="horizontal" verticalAlign="top" align="center" />
                  <XAxis dataKey="x" tickFormatter={formatXAxis} />
                  <YAxis />
                  <CartesianGrid />

                  {renderCharts(resultSet)}

                  <Tooltip content={<CustomTooltip/>}/>
                </BarChart>
              </ResponsiveContainer>
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default Passes;
