import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { QueryRenderer } from '@cubejs-client/react';
import dayjs from '../dayjs';
import Utils from '../Utils';
import CsvDownloader from '../CsvDownloader';
import CustomTooltip from '../CustomTooltip';

const cubejsApi = Utils.cubejsApi();

function PassUsersChannel(props: { period: 'day' | 'week' | 'month' | 'quarter' | 'year'; stack: string; title: string }) {
  const colors = {
    テイクアウト: '#4285F4',
    イートイン: '#8bb4f8',
    個別デリバリー: '#DB4437',
    法人デリバリー: '#F4B400',
    スタンド: '#ff944d',
    社食: '#85e085',
    その他: '#8BC34A',
  };

  const formatXAxis = (tickItem) => {
    if (!tickItem) {
      return 'N/A';
    }
    const a = tickItem.split(',');
    return Utils.formatXAxisLabel(a[0], props.period);
  };

  const segments: Array<string> = [];

  const sortOrder = ['テイクアウト', 'イートイン', '個別デリバリー', '法人デリバリー', 'スタンド', '社食', ];
  const sortSeries = (a, b) => {
    let positionA = sortOrder.findIndex((label) => label === a.yValues[0]);
    let positionB = sortOrder.findIndex((label) => label === b.yValues[0]);

    if (positionA < 0) {
      positionA = 99999;
    }
    if (positionB < 0) {
      positionB = 99999;
    }

    if (positionA >= positionB) {
      return 1;
    }
    return -1;
  };

  const renderCharts = (resultSet) =>
    resultSet
      .seriesNames()
      .sort(sortSeries)
      .map((series, i) => (
        <Bar
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.yValues[0]}
          fill={colors[series.yValues[0]]}
        />
      ));

  return (
    <>
      <QueryRenderer
        query={{
          timeDimensions: [
            {
              dimension: 'PassUsers.activeAt',
              granularity: props.period,
              dateRange: props.period === 'day' ? 'from 1 year ago to now' : '2019-06-01 to now',
            },
          ],
          measures: ['PassUsers.numberOfUsers'],
          order: {
            'PassUsers.activeAt': 'asc',
          },
          filters: [],
          segments,
          dimensions: ['PassUsers.channel'],
        }}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
          if (!resultSet) {
            return 'Loading...';
          }
          const data = resultSet.chartPivot({
                    ...resultSet.normalizePivotConfig(),
                    fillMissingDates: false,
                  });
          return (
            <>
              <ResponsiveContainer width="100%" height={600}>
                <BarChart
                  data={data}
                  stackOffset={(props.stack as 'expand') ?? 'none'}
                >
                  <Legend layout="horizontal" verticalAlign="top" align="center" />
                  <XAxis dataKey="x" tickFormatter={formatXAxis} />
                  <YAxis />
                  <CartesianGrid />

                  {renderCharts(resultSet)}

                  <Tooltip content={<CustomTooltip/>}/>
                </BarChart>
              </ResponsiveContainer>
              <CsvDownloader title={props.title} resultSet={data} />
            </>
          );
        }}
      />
    </>
  );
}

export default PassUsersChannel;
